<template>
  <div class="view">
    <transition name="fade">
      <div class="calendar col-5" v-if="calendarJson">
        <div class="days-header">
          <div v-for="(day, i) in calendarJson.header" class="day-header" :class="{'-current': day.current}" :key="i">
            <span>{{ day.day }}</span>
            <span>{{ day.month }}</span>
            <strong>{{ day.number }}</strong>
          </div>
        </div>
        <div class="days-cols">
          <div v-for="(col, i) in calendarJson.header" class="col" :key="i"></div>
        </div>
        <div class="days-title">
          <span v-for="(title, i) in calendarJson.header" class="day-title" :key="i">
            {{ title.title }}
          </span>
        </div>
        <div class="days-all">
          <div class="days-all-slot" v-for="(slot, j) in eventsFiltered.filter((e) => e['all_day'])"
               :key="j"
               :style="'left: ' + 100 / calendarJson.header.length * parseInt(slot.start_day) + '%; width: calc(' + 100 / calendarJson.header.length * slot.nb_days + '% - 2rem)'"
               :class="{'active': (slot.id === slotActive)}">
            <div class="slot-info" @click="tcEvent(slot, 'calendar_click')">
              <span>{{ slot.infos.title }}</span>
            </div>
            <div class="item">
              <div class="item-header">
                <i class="item-icon" v-if="slot.infos.icon" :class="'icon-' + slot.infos.icon"></i>
                <a class="event-link" v-if="slot.links.share" :href="shareLink(slot.links.share, slot.infos.title)" @click="tcEvent(slot, 'calendar_shareClick')" target="_blank"><i class="icon-mail"></i> {{ $t('Share') }}</a>
              </div>
              <div class="item-header">
                <div class="item-infos">
                  <strong class="item-type" v-if="slot.infos.type">{{ slot.infos.type }}</strong>
                  <span class="item-title" v-if="slot.infos.title">{{ slot.infos.title }}</span>
                </div>
                <a class="event-link" v-if="slot.links.replay && slot.joinIn && slot.joinIn === 'passed'" :href="slot.links.replay" @click="tcEvent(slot, 'calendar_replayClick')" target="_blank">{{ $t('Replay') }}</a>
              </div>
              <div class="event">
                <span v-if="slot.date"><i class="icon-watch"></i> {{ slot.date }}</span>
                <span v-if="slot.authors"><i class="icon-speak"></i> {{ slot.authors }}</span>
                <span v-if="slot.lang"><i class="icon-assistance"></i> {{ slot.lang }}</span>
              </div>
              <div class="item-description" v-if="slot.infos.description">
                <div class="item-visual" v-if="slot.infos.description.visual">
                  <img :src="slot.infos.description.visual.src" :alt="slot.infos.description.visual.alt">
                </div>
                <p v-if="slot.infos.description.text" v-html="slot.infos.description.text"></p>
              </div>
              <div class="item-btns" v-if="slot.links.calendar || slot.links.join">
                <a class="event-link" v-if="slot.links.calendar && slot.joinIn && slot.joinIn === 'coming soon'" :href="slot.links.calendar"><i class="icon-calendar"></i> {{ $t('Add to calendar') }}</a>
                <a class="event-link -full" v-if="slot.links.join && slot.joinIn && slot.joinIn !== 'coming soon' && slot.joinIn !== 'passed'" :href="slot.links.join"><i class="icon-news_date"></i> {{ $t('Join now') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="days-content">
          <div class="timeline" :style="'top: ' + timelineTop" v-if="timelineTop"><i class="icon-watch"></i></div>
          <div class="hours">
            <template v-for="n in (lastHour - firstHour)">
              <div class="hour" :key="'hour' + n">
                <div class="hour-title">{{ n + firstHour - 1 }}{{ $t('h') }}00</div>
              </div>
              <div class="hour -half" :key="'half' + n">
                <div class="hour-title">{{ n + firstHour - 1 }}{{ $t('h') }}30</div>
              </div>
            </template>
            <div class="hour">
              <div class="hour-title">{{ lastHour }}{{ $t('h') }}00</div>
            </div>
          </div>
          <div class="slots">
            <div class="slot" v-for="(slot, j) in eventsFiltered.filter((i) => !i['all_day'])"
                 :key="j"
                 :class="[(calendarJson.header.length - (parseInt(slot.start_day) + slot.nb_days)) < Math.floor(calendarJson.header.length / 2) ? '-open-left' : '-open-right', {'active': (slot.id === slotActive)}]"
                 :style="slotPosition(slot)">
              <div class="slot-infos" :class="{'-center': slot.duration < 45 }" @click="tcEvent(slot, 'calendar_click')">
                <span class="slot-title" v-if="slot.infos.title">{{ slot.infos.title }}</span>
                <span class="slot-text" v-if="slot.authors && slot.duration > 44">{{ slot.authors }}</span>
              </div>
              <div class="item">
                <div class="item-header">
                  <i class="item-icon" v-if="slot.infos.icon" :class="'icon-' + slot.infos.icon"></i>
                  <a class="event-link" v-if="slot.links.share" :href="shareLink(slot.links.share, slot.infos.title)" @click="tcEvent(slot, 'calendar_shareClick')" target="_blank"><i class="icon-mail"></i> {{ $t('Share') }}</a>
                </div>
                <div class="item-header">
                  <div class="item-infos">
                    <strong class="item-type" v-if="slot.infos.type">{{ slot.infos.type }}</strong>
                    <span class="item-title" v-if="slot.infos.title">{{ slot.infos.title }}</span>
                  </div>
                  <a class="event-link" v-if="slot.links.replay && slot.joinIn && slot.joinIn === 'passed'" :href="slot.links.replay" @click="tcEvent(slot, 'calendar_replayClick')" target="_blank">{{ $t('Replay') }}</a>
                </div>
                <div class="event">
                  <span v-if="slot.date"><i class="icon-watch"></i> {{ slot.date }}</span>
                  <span v-if="slot.authors"><i class="icon-speak"></i> {{ slot.authors }}</span>
                  <span v-if="slot.lang"><i class="icon-assistance"></i> {{ slot.lang }}</span>
                </div>
                <div class="item-description" v-if="slot.infos.description">
                  <div class="item-visual" v-if="slot.infos.description.visual">
                    <img :src="slot.infos.description.visual.src" :alt="slot.infos.description.visual.alt">
                  </div>
                  <p v-if="slot.infos.description.text" v-html="slot.infos.description.text"></p>
                </div>
                <div class="item-btns" v-if="slot.links.calendar || slot.links.join">
                  <a class="event-link" v-if="slot.links.calendar && slot.joinIn && slot.joinIn === 'coming soon'" :href="slot.links.calendar"><i class="icon-calendar"></i> {{ $t('Add to calendar') }}</a>
                  <a class="event-link -full" v-if="slot.links.join && slot.joinIn !== 'coming soon' && slot.joinIn !== 'passed'" :href="slot.links.join"><i class="icon-news_date"></i> {{ $t('Join now') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'Calendar',
  props: ['calendarJson', 'eventsFiltered', 'events'],
  data() {
    return {
      calendar: null,
      timelineTop: 0,
      firstHour: 0,
      lastHour: 0,
      slotActive: null
    }
  },
  watch: {
    eventsFiltered () {
      this.mountList()
    }
  },
  mounted () {
    if (this.calendarJson) {
      this.mountList()
    }
  },
  methods: {
    mountList() {

      if (this.events.length === this.eventsFiltered.length && this.eventsFiltered.filter((e) => e.global).length && this.events.length !== this.eventsFiltered.filter((e) => e.global).length) {
        this.$emit("filterEvents", { event: null, type: 'global' });
      }

      this.interval = setInterval(() => {
        for(let i = 0; i <= this.eventsFiltered.length - 1; i++) {
          this.joinIn(i)
          this.$forceUpdate()
        }
      }, 1000)

      // Move red bar
      this.timelinePosition()

      // Event click out to close active slot
      this.$el.addEventListener('click', this.calendarClick)

      // Open selected slot on map
      if (this.$route.query.event) {
        this.slotActive = this.$route.query.event
        setTimeout(() => {
          let $event = this.$el.querySelector('.slot.active') || this.$el.querySelector('.days-all-slot.active')
          document.querySelector('.views').scrollTo({
            top: $event.offsetTop + $event.parentNode.offsetTop,
            behavior: 'smooth',
          })
        }, 100)
      }
    },
    shareLink(str,title){
      return str.replace('$title', encodeURIComponent(title)).replace('$link', document.location.href.split('?')[0])
    },
    joinIn(item) {
      let date = new Date;
      let eventDate = new Date(this.eventsFiltered[item].month + ' ' + this.eventsFiltered[item].day + ', ' + this.eventsFiltered[item].year + ' ' + this.eventsFiltered[item].hour + ':' + this.eventsFiltered[item].minutes + ':00')
      let time = Math.floor((eventDate - date) / 1000 / 60)
      if (time > 0 && time <= window.progData['join-now-start']) {
        this.eventsFiltered[item].live = true
        this.eventsFiltered[item].joinIn = this.$t('In x minutes', { 'time': time })
      } else {
        if (time <= 0) {
          if (time >= (-1 * this.eventsFiltered[item].duration)) {
            this.eventsFiltered[item].live = true
            this.eventsFiltered[item].joinIn = 'live'
          } else {
            this.eventsFiltered[item].live = false
            this.eventsFiltered[item].joinIn = 'passed'
          }
        } else {
          this.eventsFiltered[item].joinIn = 'coming soon'
        }
      }
    },
    timelinePosition () {
      this.firstHour = this.calendarJson.first_hour
      this.lastHour = this.calendarJson.last_hour
      setInterval(() => {
        let date = new Date;
        let h = date.getHours()
        if (h >= this.firstHour && h <= this.lastHour) {
          let event = new Date((date.getMonth() + 1) + ' ' + date.getDate() + ', ' + date.getFullYear() + ' ' + this.firstHour + ':00:00')
          this.timelineTop = Math.floor((date - event) / 1000) * 0.025 * 1.5 + 'px';
        } else {
          this.timelineTop = 0
        }
      }, 1000)
    },
    calendarClick(e) {
      if (!e.composedPath().find(a => {
        if (a.classList) {
          return a.classList.contains('slot') || a.classList.contains('days-all-slot')
        }
      })) {
        this.slotActive = null
        if (this.$route.query && this.$route.query.event) {
          this.$router.replace({'query': null})
        }
      }
    },
    slotPosition(slot) {
      let top = ((slot.hour - this.firstHour) * 90 * 1.5 + slot.minutes * 1.483333333 * 1.5) + 1
      let left = 100 / this.calendarJson.header.length * parseInt(slot.start_day)
      let width = 100 / this.calendarJson.header.length * slot.nb_days
      let height = (slot.duration * 1.483333333 * 1.5 + Math.ceil(slot.duration / 60) - 2)
      return 'top: ' + top + 'px; left: ' + left + '%; width: calc(' + width + '% - 2rem); height: ' + height + 'px;'
    },
    tcEvent(slot, eventId) {
      if (eventId === 'calendar_click') {
        this.slotActive = slot.id
      }

      window.tc_events_global(this, eventId, {
        evt_category: 'calendar',
        evt_button_action: eventId,
        evt_button_label: slot.infos.title
      })

      console.log('TAGCO => tc_events_global: ', eventId, {
        evt_category: 'calendar',
        evt_button_action: eventId,
        evt_button_label: slot.infos.title
      })
    }
  },
  destroyed: function () {
    window.removeEventListener('click', this.calendarClick)
  }
}
</script>


<style lang="scss" scoped>

.view {
  padding: 0 4rem 4rem 8rem;

  .calendar {
    position: relative;
  }

  .days {
    &-header {
      position: relative;
      z-index: 10;
      display: flex;
      width: 100%;
      min-height: 15rem;
    }

    &-cols {
      position: absolute;
      left: 0;
      top: 15rem;
      bottom: 0;
      width: 100%;
      display: flex;

      .col {
        height: 100%;
        width: 100%;
        flex-grow: 1;
        margin: 0 1rem;
        border-radius: 0 0 4px 4px;
        position: relative;
        &:before{
          content: "";
          display: block;
          margin: 0;
          position: absolute;
          z-index: 5;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--primary);
          opacity: 0.35;
        }
        box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.1);
      }
    }

    &-title {
      position: relative;
      z-index: 10;
      display: flex;
      width: 100%;
      align-items: center;
    }

    &-all {
      position: relative;

      &-title {
        position: absolute;
        left: 0;
        top: 1rem;
        transform: translateX(-100%);
        color: #485C74;
        font-weight: normal;
        letter-spacing: 0.71px;
      }

      &-slot {
        // position: absolute;
        position: relative;
        display: block;
        color: #1A1B1C;
        margin: 0 1rem 1rem;
        text-align: center;
        transition: all 0.2s ease-in-out;

        &:before {
          position: absolute;
          z-index: 1;
          opacity: 0;
          left: 50%;
          top: calc(100% + 2rem);
          display: block;
          content: '';
          width: 4rem;
          height: 4rem;
          transform: translate(-50%, -30%) rotate(45deg);
          pointer-events: none;
          transition: all 0.2s ease-in-out;
          box-shadow: -2px -2px 5px 0 rgba(0,0,0,0.1);
        }

        &:after {
          position: absolute;
          z-index: 3;
          opacity: 0;
          left: 50%;
          top: calc(100% + 2rem);
          display: block;
          content: '';
          border-bottom: 2rem solid white;
          border-left: 2rem solid transparent;
          border-right: 2rem solid transparent;
          pointer-events: none;
          transition: all 0.2s ease-in-out;
          transform: translate(-50%, -100%);
        }

        .item {
          z-index: 2;
          text-align: left;
          top: calc(100% + 2rem);
          left: 50%;
          transform: translateX(-50%);
        }

        &.active {
          z-index: 100;
          box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);

          .item, &:before, &:after,  {
            opacity: 1;
            pointer-events: all;
          }
        }

        .slot-info {
          cursor: pointer;
          font-size: em(18);
          line-height: em(18, 18);
          padding: 1.1rem 1rem;

          span {
            position: relative;
            font-weight: 400;
            z-index: 10;
            display: inline-block;
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $secondary;
            opacity: 0.35;
          }
        }

        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }

    &-content {
      position: relative;
      z-index: 10;
      padding-bottom: 10rem;
    }
  }

  .day {
    &-header {
      flex-grow: 1;
      width: 100%;
      margin: 0 1rem;
      padding: 2rem;
      color: #FFFFFF;
      text-align: center;
      border-radius: 4px 4px 0 0;
      background: linear-gradient(180deg, $primary-lighter 0%, $primary 100%);

      @include bp-down(xl) {
        padding: 1rem;
      }

      &.-current {
        background: linear-gradient(180deg, $secondary-lighter 0%, $secondary 100%);
      }

      span {
        display: block;
        font-size: em(14);
        font-weight: normal;
        line-height: em(17, 14);
        text-transform: uppercase;
      }

      strong {
        margin-top: 1rem;
        display: block;
        font-weight: 300;
        font-size: em(70);
        line-height: em(70, 74);
        text-transform: uppercase;
      }
    }

    &-title {
      flex-grow: 1;
      margin: 0 1rem;
      padding: 1rem;
      color: #1A1B1C;
      font-weight: 400;
      font-size: em(18);
      line-height: em(24, 18);
      width: 100%;
      overflow: hidden;
      text-align: center;
    }
  }

  .hours {
    position: relative;
  }

  .hour {
    position: relative;
    height: 6.75rem;

    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 1rem;
      height: .1rem;
      width: calc(100% - 2rem);
      border-top: 1px dashed #8996A4;
      overflow: hidden;
    }

    &-title {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-100%, -50%);
      color: #485C74;
      font-weight: normal;
      letter-spacing: 0.71px;
    }

    &.-half {

      &:before {
        opacity: 0.4;
      }

      .hour-title {
        font-weight: 200;
        font-style: italic;
      }
      .hour-slots {

      }
    }
  }

  .slots {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slot {
    z-index: 20;
    position: absolute;
    top: .1rem;
    margin: 0 1rem;
    transition: all 0.2s ease-in-out;

    &.-open-left {

      .item {
        left: 0;
        z-index: 2;
        transform: translateX(calc(-100% - 2rem));
      }

      &:before {
        position: absolute;
        z-index: 1;
        opacity: 0;
        left: 0;
        top: 0;
        display: block;
        content: '';
        width: 4rem;
        height: 4rem;
        transform: translateX(-120%) rotate(45deg);
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
      }

      &:after {
        position: absolute;
        z-index: 3;
        opacity: 0;
        left: 0;
        top: 0;
        display: block;
        content: '';
        border-left: 2rem solid white;
        border-top: 2rem solid transparent;
        border-bottom: 2rem solid transparent;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        transform: translateX(-100%);
      }
    }

    &.-open-right {
      .item {
        right: 0;
        z-index: 2;
        transform: translateX(calc(100% + 2rem));
      }

      &:before {
        position: absolute;
        z-index: 1;
        opacity: 0;
        right: 0;
        top: 0;
        display: block;
        content: '';
        width: 4rem;
        height: 4rem;
        transform: translateX(120%) rotate(45deg);
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
      }

      &:after {
        position: absolute;
        z-index: 3;
        opacity: 0;
        right: 0;
        top: 0;
        display: block;
        content: '';
        border-right: 2rem solid white;
        border-top: 2rem solid transparent;
        border-bottom: 2rem solid transparent;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        transform: translateX(100%);
      }
    }

    &.active {
      z-index: 100;
      box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);

      &:before, &:after, .item {
        opacity: 1;
        pointer-events: all;
      }
    }

    &-infos {
      cursor: pointer;
      padding: 1rem;
      background: $primary;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &.-center {
        justify-content: center;
      }
    }

    &-title {
      color: white;
      display: block;
      @include bp-down(xl) {
        font-size: em(14);
      }
    }

    &-text {
      color: white;
      font-size: em(11);
      @include bp-down(xl) {
        font-size: em(10);
      }
      line-height: em(13, 11);
      margin-top: .4rem;
    }
  }

  .item {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: -4rem;
    width: 35rem;
    padding: 2rem;
    background: white;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);

    &-header {
      display: flex;
      margin-bottom: 1.4rem;
      align-items: center;
      justify-content: space-between;
    }

    &-icon {
      font-size: 40px;
      color: $primary;
    }

    &-infos {
      margin-right: 2rem;

      + button {
        align-self: flex-end;
      }
    }

    &-type {
      display: inline-block;
      font-weight: 300;
      font-size: em(18);
      line-height: em(22, 18);
      color: #1A1B1C;
      width: 100%;
      text-transform: uppercase;
    }

    &-title {
      display: inline-block;
      color: #1A1B1C;
      font-weight: 300;
      width: 100%;
    }

    &-description {
      font-size: em(14);
      line-height: em(16, 14);
      margin-bottom: 2rem;
      overflow-x: auto;

      &:after {
        display: block;
        content: "";
        clear: both;
      }

      p {
        margin-bottom: 0;
      }
    }

    &-visual {
      max-width: 12rem;
      float: left;
      margin-right: 1rem;
    }

    &-btns {
      display: flex;
      justify-content: flex-start;
    }
  }

  .event {
    margin-bottom: 1.8rem;
    border-bottom: 1px dashed $primary-lighter;

    i {
      display: inline-block;
      font-size: em(18, 12);
      color: $primary;
      vertical-align: middle;
      position: relative;
      top: -.1rem;
      margin-right: 0.4rem;
    }

    span {
      display: block;
      padding: 1rem 0;
      font-size: em(12);
      line-height: em(14, 12);
      border-top: 1px dashed $primary-lighter;;
    }

    & + .event {
      padding-top: 1.8rem;
      border-top: 1px solid $border;
    }
  }

  .timeline {
    position: absolute;
    z-index: 30;
    height: .2rem;
    width: 100%;
    background: #e74e0f;
    transition: all 1s linear;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);

    i {
      display: block;
      position: absolute;
      right: -5px;
      top: 0;
      font-size: em(24);
      color: #e74e0f;
      transform: translate(100%, -50%);
    }
  }
}
</style>
